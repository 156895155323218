<template>
  <div class="app-news">
    <div v-if="loading" class="mb-3">
      <pulse-loader
        color="#ffffff"
        size="10px"
      ></pulse-loader>
    </div>
    <div v-if="news">
      <div class="row justify-content-center type-choice mb-3">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="input-group">
            <label
              class="btn btn-dark"
              for="typeTopRadio"
            >
              Top
            </label>
            <input
              class="btn-check"
              type="radio"
              name="typeRadio"
              value="top"
              id="typeTopRadio"
              v-model="type"
              autocomplete="off"
            />

            <input
              class="btn-check"
              type="radio"
              name="typeRadio"
              value="new"
              id="typeNewRadio"
              v-model="type"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="typeNewRadio"
            >
              New
            </label>

            <input
              class="btn-check"
              type="radio"
              name="typeRadio"
              value="ask"
              id="typeAskRadio"
              v-model="type"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="typeAskRadio"
            >
              Ask
            </label>

            <input
              class="btn-check"
              type="radio"
              name="typeRadio"
              value="show"
              id="typeShowRadio"
              v-model="type"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="typeShowRadio"
            >
              Show
            </label>

            <input
              class="btn-check"
              type="radio"
              name="typeRadio"
              value="job"
              id="typeJobRadio"
              v-model="type"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="typeJobRadio"
            >
              Job
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-center paginator mb-3">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="input-group">
            <button
              class="btn btn-dark"
              type="button"
              @click="firstPage"
            >
              <BIconChevronBarLeft />
            </button>
            <button
              class="btn btn-dark"
              type="button"
              @click="prevPage"
            >
              <BIconChevronDoubleLeft />
            </button>
            <input
              type="text"
              class="form-control"
              v-model="page"
            />
            <span class="input-group-text">/ {{ maxPage }}</span>
            <button
              class="btn btn-dark"
              type="button"
              @click="nextPage"
            >
              <BIconChevronDoubleRight />
            </button>
            <button
              class="btn btn-dark"
              type="button"
              @click="lastPage"
            >
              <BIconChevronBarRight />
            </button>
          </div>
        </div>
      </div>
      <dynamic-scroller
        :items="news"
        :min-item-size="1"
        class="news"
      >
        <template v-slot="{ item, index, active }">
          <dynamic-scroller-item
            :item="item"
            :active="active"
            :size-dependencies="[
              item.title,
              item.url,
            ]"
            :data-index="index"
          >
            <a
              class="single"
              target="_blank"
              :href="item.url"
            >
              <div class="wrapper">
                <div class="primary">
                  <span class="title">{{ item.title }}</span>
                  <span
                    class="subtitle text-muted"
                    v-if="item.url"
                  >
                    ({{ getHostname(item.url) }})
                  </span>
                </div>
                <div class="secondary text-muted">
                  <span class="score">
                    {{ item.score }} points
                  </span>
                  <span class="author">
                    by {{ item.by }}
                  </span>
                  |
                  <span class="date">
                    {{ dateFromNow(item.time) }}
                  </span>
                  |
                  <span class="comments">
                    {{ item.descendants || '0' }} comments
                  </span>
                </div>
              </div>
            </a>
          </dynamic-scroller-item>
        </template>
      </dynamic-scroller>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import moment from 'moment';

export default {
  name: 'AppNews',
  components: {
    PulseLoader,
    DynamicScroller,
    DynamicScrollerItem,
  },
  data() {
    return {
      loading: false,
      type: 'top',
      ids: null,
      news: null,
      page: 1,
      perPage: 30,
    };
  },
  mounted() {
    this.fetchNews();
  },
  watch: {
    type() {
      this.fetchNews();
    },
  },
  computed: {
    maxPage() {
      if (!this.ids || 0 === this.ids.length) return 1;
      return Math.ceil(this.ids.length / this.perPage);
    },
  },
  methods: {
    fetchNews() {
      this.loading = true;
      let uri = '';
      if (['top', 'new', 'ask', 'show', 'job'].includes(this.type))
        uri = `https://hacker-news.firebaseio.com/v0/${this.type}stories.json`;
      else
        return;
      this.axios
        .get(uri)
        .then(news => {
          this.ids = news.data;
          this.fetchNewsFromIds(this.ids);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchNewsFromIds(ids) {
      if (!ids || 0 === ids.length) return;
      console.log('here');
      this.loading = true;
      let promises = [];
      const idStart = Math.max(1, (this.page - 1) * this.perPage);
      const idEnd = Math.min(ids.length, this.page * this.perPage);
      console.log('idStart', idStart, 'idEnd', idEnd);
      const pageIds = ids.slice(idStart, idEnd);
      console.log(pageIds);
      for (const id of pageIds) {
        promises.push(
          this.axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`)
        );
      }

      Promise.all(promises)
        .then(results => {
          this.news = results
            .map(result => result.data)
            .sort((a, b) => {
              if ('top' === this.type)
                return ids.indexOf(a.id) - ids.indexOf(b.id);
                //return a.score < b.score ? 1 : -1;
              return a.time < b.time ? 1 : -1;
            });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        })
    },
    getHostname(url) {
      return (new URL(url)).hostname.replace('www.', '');
    },
    dateFromNow(time) {
      return moment.unix(time).fromNow();
    },
    firstPage() {
      this.page = 1;
      this.fetchNewsFromIds(this.ids);
    },
    prevPage() {
      this.page = Math.max(1, this.page - 1);
      this.fetchNewsFromIds(this.ids);
    },
    nextPage() {
      this.page = Math.min(this.maxPage, this.page + 1);
      this.fetchNewsFromIds(this.ids);
    },
    lastPage() {
      this.page = this.maxPage;
      this.fetchNewsFromIds(this.ids);
    },
  },
}
</script>

<style scoped>
.app-news {
  padding: 20px 10px;
}

.news {
  text-align: left;
}

.news .single {
  color: #ffffff;
  text-decoration: none;
}

.news .wrapper {
  margin-bottom: 15px;
}

.news .primary .subtitle {
  margin-left: 10px;
}

.news .secondary {
  font-size: .8em;
}

.news .secondary .author {
  margin-left: 3px;
}

.type-choice .input-group > label {
  flex-grow: 1;
}
</style>
