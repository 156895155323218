<template>
  <div class="app-statuses">
    <div v-if="loading">
      <pulse-loader
        color="#ffffff"
        size="10px"
      ></pulse-loader>
    </div>
    <div v-if="statuses" class="container">
      <div class="row justify-content-center">
        <router-link
          v-for="status in statuses"
          :key="status.id"
          class="col-sm-12 col-md-6 col-lg-4 status-link"
          :to="{ name: 'status', params: { id: status.id } }"
        >
          <div class="app-statuses__app">
            <div class="app__icon">
              <BIconCheckCircleFill
                v-if="status.appStatus == 'success'"
                :color="status.appStatus"
              />
              <BIconDashCircleFill
                v-if="status.appStatus == 'warning'"
                :color="status.appStatus"
              />
              <BIconXCircleFill
                v-if="status.appStatus == 'error'"
                :color="status.appStatus"
              />
              <BIconCircleFill
                v-if="status.appStatus == 'undefined'"
                :color="status.appStatus"
              />
            </div>
            <div class="app__infos">
              <div class="infos__primary">
                {{ status.name }}
              </div>
              <div class="infos__secondary">
                <BIconClockFill />
                <span class="text">
                  {{ status.lastUpdate }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import moment from 'moment';

const apiBasePath = process.env.VUE_APP_STATUS_API;

export default {
  name: 'AppStatuses',
  components: {
    'pulse-loader': PulseLoader,
  },
  data() {
    return {
      loading: true,
      statuses: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.axios
        .get(`${apiBasePath}`)
        .then(response => {
          if (200 !== response.status)
            return;
          this.statuses = response.data;
          console.log(this.statuses);
        })
        .catch(() => {
          // error
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDayStatus(results) {
      const dayResults = this.filterBetween(
        results,
        moment().startOf('day'),
        moment().endOf('day'),
      );

      let hasSuccess = false;
      let hasError = false;

      for (const result of dayResults) {
        if (result.responded && 200 == result.status)
          hasSuccess = true;
        else if (!result.responded || 200 !== result.status)
          hasError = true

        if (hasSuccess && hasError)
          break;
      }

      if (!hasError && hasSuccess)
        return 'success';
      else if (hasError && !hasSuccess)
        return 'error';
      else if (hasError && hasSuccess)
        return 'warning';
      else if (!hasError && !hasSuccess)
        return 'undefined';
    },
    filterBetween(results, start, end) {
      return results.filter(result => {
        return moment(result.date).isSameOrAfter(start)
          && moment(result.date).isSameOrBefore(end)
      });
    },
    lastUpdate(results) {
      let last = null;
      for (const result of results) {
        const mDate = moment(result.date);
        if (null === last || mDate.isAfter(last))
          last = mDate;
      }
      return last ? last.format('YYYY-MM-DD HH:mm:ss') : null;
    },
  },
}
</script>

<style scoped>
.status-link {
  text-decoration: none;
  color: #ffffff;
}

.app-statuses {
  padding-top: 20px;
}

.app-statuses__app {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #21252b;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.app__icon [color=success] {
  fill: green;
}
.app__icon [color=error] {
  fill: red;
}
.app__icon [color=warning] {
  fill: orange;
}
.app__icon [color=undefined] {
  fill: grey;
}

.app__icon > svg {
  height: 30px;
  width: 30px;
}

.app__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: 20px;
}

.infos__primary {
  font-weight: bold;
  font-size: 1.2em;
}

.infos__secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: .8em;
  color: darkgrey;
  fill: darkgrey;
  margin-top: 5px;
}

.infos__secondary > .text {
  margin-left: 5px;
}
</style>
