<template>
  <div class="app-header">
    <div class="app-header__top">
      <div
        class="top__toggle-menu"
        @click="toggleMenu"
      >
        <BIconList v-if="!menuOpened" />
        <BIconX v-if="menuOpened" />
      </div>
      <div class="spacer" />
      <div
        v-if="updateAvailable"
        class="top__update"
        @click="updateApp"
      >
        <BIconArrowRepeat />
      </div>
      <div
        v-if="!online"
        class="top__offline"
      >
        <BIconWifiOff />
      </div>
    </div>
    <div class="app-header__body">
      <div
        class="app-header__left-menu"
        :class="{ 'left-menu--opened': menuOpened }"
      >
        <router-link
          :to="{ name: 'home' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon">
            <BIconHouseFill />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'currency' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon">
            <BIconCurrencyExchange />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'weather' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon">
            <BIconCloudSun />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'statuses' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon">
            <BIconBarChartLineFill />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'news' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon">
            <BIconNewspaper />
          </div>
        </router-link>
        <div class="spacer" />
        <router-link
          :to="{ name: 'settings' }"
          class="icon-link"
          @click="closeMenu"
        >
          <div class="icon icon__settings">
            <BIconGear />
          </div>
        </router-link>
      </div>
      <div class="app-header__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import event from '@/eventBus';

export default {
  name: 'AppHeader',
  data() {
    return {
      menuOpened: false,
      online: true,
      updateAvailable: false,
    };
  },
  mounted() {
    console.log('debug 1');
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);
    event.$on('pwa-update', () => {
      console.log('receive pwa-update event1');
      this.updateAvailable = true;
    });
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
    closeMenu() {
      this.menuOpened = false;
    },
    handleConnection() {
      console.log('online', navigator.onLine);
      this.online = navigator.onLine;
    },
    updateApp() {
      window.location.reload(true);
    },
  },
}
</script>

<style scoped>
.spacer {
  flex-grow: 1;
}

.app-header {
  display: flex;
  flex-direction: column;
}

.app-header__top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  position: sticky;
  top: 0;

  background-color: #1a1d24;
  z-index: 4;
}

.app-header__top > *:last-child {
  margin-right: 10px;
}

.top__toggle-menu {
  margin-left: 5px;
}
.top__toggle-menu > svg {
  height: 40px;
  width: 40px;
}

.top__offline > svg {
  height: 30px;
  width: 30px;
  color: #c40000;
}

.top__update > svg {
  height: 30px;
  width: 30px;
  color: #c40000;
}

.app-header__body {
  display: flex;
  flex-direction: row;
}

.app-header__left-menu {
  width: 0;
  position: sticky;
  top: 50px;
  height: calc(100vh - 50px);
  background-color: #1a1d24;

  transition: transform .15s linear, width 0s linear .15s;
  transform: translateX(-55px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left-menu--opened {
  transition: transform .15s linear, width 0s linear 0s;
  transform: translateX(0);
  width: 55px;
}

.app-header__left-menu > .icon-link {
  width: 100%;
  color: inherit;
  text-decoration: none;
}
.app-header__left-menu > .icon-link > .icon {
  width: calc(100% - 10px);
  margin: 5px;
}
.app-header__left-menu > .icon-link > .icon > svg {
  width: 100%;
  height: 100%;
}

.app-header__left-menu .icon__settings {
  margin-bottom: 10px;
}

.app-header__content {
  width: 100%;
}
</style>
