<template>
  <div class="currency container">
    <div
      v-if="sortedCurrencyList"
      class="currency__select input-group"
    >
      <select
        class="form-select"
        v-model="currencyOne"
      >
        <option
          v-for="currency in sortedCurrencyList"
          :key="currency.id"
          :value="currency.id"
        >
          {{ currency.name }}
        </option>
      </select>
      <span class="input-group-text">
        <BIconArrowLeftRight />
      </span>
      <select
        class="form-select"
        v-model="currencyTwo"
      >
        <option
          v-for="currency in sortedCurrencyList"
          :key="currency.id"
          :value="currency.id"
        >
          {{ currency.name }}
        </option>
      </select>
    </div>
    <div class="convert">
      <div class="input-group">
        <input
          type="number"
          step="0.01"
          min="0"
          v-model="inputOne"
          @change="updateFromOne"
          class="form-control"
        />
        <span class="input-group-text">
          {{ currencyOne ? currencyOne.toUpperCase() : '' }}
        </span>
      </div>
      <BIconArrowLeftRight class="icon" />
      <div class="input-group">
        <input
          type="number"
          v-model="inputTwo"
          step="0.01"
          min="0"
          @change="updateFromTwo"
          class="form-control"
        />
        <span class="input-group-text">
          {{ currencyTwo ? currencyTwo.toUpperCase() : '' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const uriList = 'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies.json';
const uriConvert = 'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies';

export default {
  name: 'Currency',
  data() {
    return {
      currencyOne: null,
      currencyTwo: null,
      ONE_TWO: null,
      TWO_ONE: null,
      inputOne: 0,
      inputTwo: 0,
      currencyList: null,
      sortedCurrencyList: null,
    };
  },
  mounted() {
    this.currencyOne = localStorage.currencyOne || '';
    this.currencyTwo = localStorage.currencyTwo || '';
    this.updateCurrencies();
    this.updateCurrencyList();
  },
  watch: {
    currencyOne: 'updateCurrencyOne',
    currencyTwo: 'updateCurrencyTwo',
  },
  methods: {
    updateCurrencyList() {
      try {
        this.currencyList = JSON.parse(localStorage.currencyList);
      } catch (e) {
        this.currencyList = null;
      }

      this.axios
        .get(uriList)
        .then(list => {
          this.currencyList = list.data;
          localStorage.currencyList = JSON.stringify(this.currencyList);

          this.sortedCurrencyList = [];
          for (const index in this.currencyList) {
            this.sortedCurrencyList.push({id: index, name: this.currencyList[index]});
          }

          this.sortedCurrencyList = this.sortedCurrencyList.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });
        });
    },
    updateCurrencyOne() {
      localStorage.currencyOne = this.currencyOne;
      this.updateCurrencies();
    },
    updateCurrencyTwo() {
      localStorage.currencyTwo = this.currencyTwo;
      this.updateCurrencies();
    },
    updateCurrencies() {
      if (
        !this.currencyOne || this.currencyOne === ''
        || this.currencyOne === undefined || this.currencyOne == 'undefined'
        || this.currencyOne === null || this.currencyOne == 'null'
        || !this.currencyTwo || this.currencyTwo === ''
        || this.currencyTwo === undefined || this.currencyTwo == 'undefined'
        || this.currencyTwo === null || this.currencyTwo == 'null'
      ) return;

      if (localStorage[`${this.currencyOne}_${this.currencyTwo}`])
        this.ONE_TWO = localStorage[`${this.currencyOne}_${this.currencyTwo}`];
      if (localStorage[`${this.currencyTwo}_${this.currencyOne}`])
        this.TWO_ONE = localStorage[`${this.currencyTwo}_${this.currencyOne}`];

      this.axios
        .get(`${uriConvert}/${this.currencyOne}/${this.currencyTwo}.min.json`)
        .then(currency => {
          console.log('currency', currency);
          this.ONE_TWO = currency.data[this.currencyTwo];
          localStorage[`${this.currencyOne}_${this.currencyTwo}`] = this.ONE_TWO;
        });

      this.axios
        .get(`${uriConvert}/${this.currencyTwo}/${this.currencyOne}.min.json`)
        .then(currency => {
          this.TWO_ONE = currency.data[this.currencyOne];
          localStorage[`${this.currencyTwo}_${this.currencyOne}`] = this.TWO_ONE;
        });
    },
    updateFromOne() {
      this.inputTwo = Math.round(this.inputOne * this.ONE_TWO * 100) / 100;
    },
    updateFromTwo() {
      this.inputOne = Math.round(this.inputTwo * this.TWO_ONE * 100) / 100;
    },
  },
}
</script>

<style scoped>
.currency {
  padding-top: 30px;
}

.from-to, .to-from {
  display: flex;
  align-items: center;
  justify-content: center;
}

.convert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.convert > .icon {
  transform: rotate(90deg);
  margin: 15px 0;
}
</style>
