<template>
  <div class="weather-page">
    <div v-if="geolocationError">
      Impossible d'accéder à votre géolocalisation. Merci d'autoriser l'accès.
    </div>
    <div v-if="!geolocationError">
      <div
        v-if="updateWeather"
        class="weather__loading"
      >
        <pulse-loader
          color="#ffffff"
          size="10px"
        ></pulse-loader>
      </div>
      <div 
        v-if="consolidatedWeather"
        class="container"
      >
        <div class="row justify-content-center">
          <div
            v-for="item in consolidatedWeather"
            :key="item.id"
            class="col-sm-12 col-md-4 col-lg-2 weather"
          >
            <div class="weather__date">
              {{ getWeatherDate(item) }}
            </div>
            <div class="weather__primary">
              <img
                class="primary__state"
                :src="`${apiBasePathStatic}/${item.weather_state_abbr}.svg`"
              />
              <span class="primary__temp">
                {{ Math.round(item.the_temp) }}°
              </span>
            </div>
            <div class="weather__text">
              {{ item.weather_state_name }}
            </div>
            <div class="weather__secondary">
              <span class="secondary__min-temp">
                <BIconArrowDownCircleFill />
                {{ Math.round(item.min_temp) }}°
              </span>
              <span class="secondary__max-temp">
                <BIconArrowUpCircleFill />
                {{ Math.round(item.max_temp) }}°
              </span>
              <span class="secondary__wind">
                <BIconWind />
                <BIconArrowUp
                  v-if="getWindDirection(item) == 'N'"
                />
                <BIconArrowUpRight
                  v-else-if="getWindDirection(item) == 'NE'"
                />
                <BIconArrowRight
                  v-else-if="getWindDirection(item) == 'E'"
                />
                <BIconArrowDownRight
                  v-else-if="getWindDirection(item) == 'SE'"
                />
                <BIconArrowDown
                  v-else-if="getWindDirection(item) == 'S'"
                />
                <BIconArrowDownLeft
                  v-else-if="getWindDirection(item) == 'SW'"
                />
                <BIconArrowLeft
                  v-else-if="getWindDirection(item) == 'W'"
                />
                <BIconArrowUpLeft
                  v-else-if="getWindDirection(item) == 'NW'"
                />
              </span>
              <span class="secondary__humidity">
                <BIconDropletFill />
                {{ item.humidity }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import moment from 'moment';

// const apiBasePath = 'http://localhost:5000/api/v1/weather';
const apiBasePath = process.env.VUE_APP_WEATHER_API;

export default {
  name: 'Weather',
  components: {
    'pulse-loader': PulseLoader,
  },
  data() {
    return {
      geolocationError: true,
      updateWeather: false,
      lat: 0,
      long: 0,
      weather: null,
      apiBasePathStatic: `${apiBasePath}/static`,
    };
  },
  mounted() {
    // cached weather
    if (localStorage.weather) {
      this.weather = JSON.parse(localStorage.weather);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getLocation);
    } else {
      console.log('error geolocation');
    }
  },
  computed: {
    consolidatedWeather() {
      return (this.weather && this.weather.consolidated_weather)
        ? this.weather.consolidated_weather
        : null;
    },
  },
  methods: {
    getLocation(position) {
      this.geolocationError = false;
      this.updateWeather = true;
      console.log('====== position', position);

      // https://www.metaweather.com/api/location/search/?lattlong=50.068,-5.316
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
      this.axios.get(`${apiBasePath}/${this.lat}/${this.long}`)
        .then(response => {
          console.log('======= response', response);
          if (200 !== response.status)
            return this.weather = null;
          this.weather = response.data;
          localStorage.weather = JSON.stringify(this.weather);
          this.updateWeather = false;
        })
        .catch(() => {
          this.updateWeather = false;
        });
    },
    getWeatherDate(item) {
      return moment(item.applicable_date, 'YYYY-MM-DD')
        .format('dddd, MMMM Do YYYY');
    },
    getWindDirection(item) {
      switch(item.wind_direction_compass) {
        case 'NNW':
        case 'NbW':
        case 'N':
        case 'NbE':
        case 'NNE':
          return 'N';
        case 'NEbN':
        case 'NE':
        case 'NEbE':
          return 'NE';
        case 'ENE':
        case 'EbN':
        case 'E':
        case 'EbS':
        case 'ESE':
          return 'E';
        case 'SEbE':
        case 'SE':
        case 'SEbS':
          return 'SE';
        case 'SSE':
        case 'SbE':
        case 'S':
        case 'SbW':
        case 'SSW':
          return 'S';
        case 'SWbS':
        case 'SW':
        case 'SWbW':
          return 'SW';
        case 'WSW':
        case 'WbS':
        case 'W':
        case 'WbN':
        case 'WNW':
          return 'W';
        case 'NWbW':
        case 'NW':
        case 'NWbN':
          return 'NW';
        default:
          return 'N';
      }
    }
  },
}
</script>

<style scoped>
.weather-page {
  padding-top: 10px;
}

.weather {
  margin-bottom: 50px;
}

.weather__date {
  margin-bottom: 20px;
}

.weather__primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.primary__state {
  max-width: 60px;
}

.primary__temp {
  font-size: 70px;
  max-height: 60px;
  margin-left: 10px;
  margin-top: -45px;
}

.weather__text {
  font-size: 20px;
  margin-bottom: 10px;
}

.secondary__max-temp, .secondary__wind, .secondary__humidity {
  margin-left: 10px;
}

.weather__loading {
  margin-bottom: 20px;
}
</style>
