import { createApp } from 'vue';
import * as VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

import App from './App.vue';
import Home from './components/Home.vue';
import Currency from './components/Currency.vue';
import Weather from './components/Weather.vue';
import AppStatuses from './components/AppStatuses.vue';
import AppStatus from './components/AppStatus.vue';
import AppNews from './components/AppNews.vue';
import Settings from './components/Settings.vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import runtime from "serviceworker-webpack-plugin/lib/runtime";
import './registerServiceWorker.js';

if ("serviceWorker" in navigator) {
  runtime.register();
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { transition: 'slide-right' },
  },
  {
    path: '/currency',
    name: 'currency',
    component: Currency,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/weather',
    name: 'weather',
    component: Weather,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/statuses/:id',
    name: 'status',
    component: AppStatus,
    meta: { transition: 'slide-left' },
    props: true,
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: AppStatuses,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/news',
    name: 'news',
    component: AppNews,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { transition: 'slide-right' },
  },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})

const app = createApp(App);

app.use(router);
app.use(VueAxios, axios)
app.use(BootstrapIconsPlugin);

app.mount('#app');
