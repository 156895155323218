<template>
  <app-header>
    <template #content>
      <router-view class="child-view" v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" :key="route.name" />
        </transition>
      </router-view>
    </template>
  </app-header>
</template>

<script>
import AppHeader from '@/components/AppHeader';

export default {
  name: 'App',
  components: {
    'app-header': AppHeader,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background-color: #282c34;
  min-height: 100vh;
}
</style>

<style>
.fade-enter-active, .fade-leave-active {
  position: absolute;
  min-height: calc(100vh - 50px);
  transition: opacity .75s ease;
}
.fade-enter, .fade-leave-active {
  position: absolute;
  min-height: calc(100vh - 50px);
  opacity: 0;
}
.child-view {
  transition: all .75s cubic-bezier(.55,0,.1,1);
  background-color: #282c34;
  z-index: 2;
}
.child-view:first-of-type {
  z-index: 3;
}
.slide-left-enter, .slide-right-leave-active {
  position: absolute;
  min-height: calc(100vh - 50px);
  -webkit-transform: translate(100vw, 0);
  transform: translate(100vw, 0);
  transition: transform .3s;
}
.slide-left-leave-active, .slide-right-enter {
  position: absolute;
  min-height: calc(100vh - 50px);
  -webkit-transform: translate(-100vw, 0);
  transform: translate(-100vw, 0);
  transition: transform .3s;
}
</style>
