<template>
  <div class="apps">
    <div class="container">
      <div class="row justify-content-center">
        <router-link
          :to="{ name: 'currency' }"
          class="col-12 col-sm-6 col-md-4 col-lg-2 icon-link"
        >
          <div class="icon">
            <BIconCurrencyExchange />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'weather' }"
          class="col-12 col-sm-6 col-md-4 col-lg-2 icon-link"
        >
          <div class="icon">
            <BIconCloudSun />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'statuses' }"
          class="col-12 col-sm-6 col-md-4 col-lg-2 icon-link"
        >
          <div class="icon">
            <BIconBarChartLineFill />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'news' }"
          class="col-12 col-sm-6 col-md-4 col-lg-2 icon-link"
        >
          <div class="icon">
            <BIconNewspaper />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>
.icon-link {
  font-size: 5em;
  color: inherit;
  text-decoration: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.icon:hover {
  background-color: #21252b;
}
</style>
