<template>
  <div class="settings">
    Coming soon...
  </div>
</template>

<script>
export default {
  name: 'Settings',
}
</script>

<style scoped>
</style>
