<template>
  <div class="app-status container">
    <div v-if="loading">
      <pulse-loader
        color="#ffffff"
        size="10px"
        ></pulse-loader>
    </div>
    <template v-if="site">
      <div class="row justify-content-center mb-4">
        <div class="col-12">
          <div class="input-group">
            <!-- x hours before -->
            <input
              type="number"
              class="form-control"
              v-model="scopeHours"
              @focus="scope='hours'"
            />
            <input
              type="radio"
              class="btn-check"
              id="scope-hours"
              v-model="scope"
              value="hours"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="scope-hours"
            >
              past hours
            </label>

            <!-- x days before -->
            <input
              type="number"
              class="form-control"
              v-model="scopeDays"
              @focus="scope='days'"
            />
            <input
              type="radio"
              class="btn-check"
              id="scope-days"
              v-model="scope"
              value="days"
              autocomplete="off"
            />
            <label
              class="btn btn-dark"
              for="scope-days"
            >
              past days
            </label>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mb-4">
        <div class="col-sm-12 col-md-6 col-lg-4 app-status__header">
          <div class="header__icon">
            <BIconCheckCircleFill
              v-if="getStatus(site.results) == 'success'"
              :color="getStatus(site.results)"
            />
            <BIconDashCircleFill
              v-if="getStatus(site.results) == 'warning'"
              :color="getStatus(site.results)"
            />
            <BIconXCircleFill
              v-if="getStatus(site.results) == 'error'"
              :color="getStatus(site.results)"
            />
            <BIconCircleFill
              v-if="getStatus(site.results) == 'undefined'"
              :color="getStatus(site.results)"
            />
          </div>
          <div class="header__infos">
            <div class="infos__primary">
              {{ site.name }}
            </div>
            <div class="infos__secondary">
              <BIconClockFill />
              <span class="text">
                {{ lastUpdate(site.results) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mb-4">
        <div class="col-12">
          <line-chart
            :chartData="chartData"
            :options="chartOptions"
          />
        </div>
      </div>

      <div class="row justify-content-center mb-4">
        <div class="col-12">
          <table class="table table-dark table-striped">
            <thead>
              <tr>
                <td>Date</td>
                <td>Status</td>
                <td>Time</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="result in errorsTableData"
                :key="result.date"
              >
                <td>{{ simpleDate(result.date) }}</td>
                <td>{{ result.status }}</td>
                <td>{{ result.timeMs }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-dark btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#errorResultModal"
                    :data-bs-date="result.date"
                    :data-bs-status="result.status"
                    :data-bs-time-ms="result.timeMs"
                    :data-bs-response="result.data"
                  >
                    <BIconInfoCircleFill />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <div
      class="modal modal-dark fade"
      id="errorResultModal"
      tabindex="-1"
      aria-labelledby="errorResultModalLabel"
      aria-hidden="true"
      ref="errorResultModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="errorResultModalLabel"
            >
              Response
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-4 text-center card-date">
                <h5 class="card-title">Date</h5>
                <p class="card-text"></p>
              </div>
              <div class="col-12 col-md-4 mb-4 text-center card-status">
                <h5 class="card-title">Status</h5>
                <p class="card-text"></p>
              </div>
              <div class="col-12 col-md-4 mb-4 text-center card-time-ms">
                <h5 class="card-title">Response time</h5>
                <p class="card-text"></p>
              </div>
              <div class="col-12 card-response">
                <h5 class="card-title">Response</h5>
                <pre class="card-text"></pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { Chart, registerables } from "chart.js";
import { LineChart } from 'vue-chart-3';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables);

import moment from 'moment';

const apiBasePath = process.env.VUE_APP_STATUS_API;

export default {
  name: 'AppStatus',
  props: {
    id: String,
  },
  data() {
    return {
      loading: true,
      site: null,
      scopeHours: 1,
      scopeDays: 1,
      scope: 'hours',
    };
  },
  components: {
    PulseLoader,
    LineChart,
  },
  mounted() {
    this.fetchData();
    setInterval(() => {
      this.fetchData();
    }, 2 * 60 * 1000);

    this.$refs.errorResultModal.addEventListener('show.bs.modal', (event) => {
      console.log('show modal');
      const button = event.relatedTarget;
      const date = button.getAttribute('data-bs-date');
      const status = button.getAttribute('data-bs-status');
      const timeMs = button.getAttribute('data-bs-time-ms');
      const response = button.getAttribute('data-bs-response');

      console.log('Date', date);
      console.log('Status', status);
      console.log('TimeMs', timeMs);
      console.log('Response', response);

      const dateCard = this.$refs.errorResultModal.querySelector('.card-date .card-text');
      const statusCard = this.$refs.errorResultModal.querySelector('.card-status .card-text');
      const timeMsCard = this.$refs.errorResultModal.querySelector('.card-time-ms .card-text');
      const responseCard = this.$refs.errorResultModal.querySelector('.card-response .card-text');

      dateCard.textContent = moment(date).format('YYYY-MM-DD HH:mm:ss');
      statusCard.textContent = status;
      timeMsCard.textContent = `${timeMs}ms`;
      responseCard.textContent = response || 'N/A';
    });
  },
  watch: {
    scope(value) {
      console.log('watch scope', value);
    },
    scopeDays(value) {
      console.log('watch scopeDays', value);
    },
    scopeHours(value) {
      console.log('watch scopeHours', value);
    },
  },
  computed: {
    chartData() {
      if (!this.site) return;

      if (
        ('hours' === this.scope && (!this.scopeHours || this.scopeHours <= 0))
        || !this.scopeDays || this.scopeDays <= 0
      ) return;

      const end = moment();
      const start = moment(end).subtract(
        'hours' === this.scope ? this.scopeHours : this.scopeDays,
        this.scope || 'days'
      );

      const subResults = this.filterBetween(
        this.site.results,
        start,
        end,
      );

      subResults.sort((a, b) => a.date < b.date ? -1 : 1);

      const labels = [];
      const data = [];
      for (const res of subResults) {
        labels.push(moment(res.date).format('YYYY-MM-DD HH:mm:ss'));
        data.push(res.timeMs);
      }

      const dataset = {
        label: 'Average Response Time',
        data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132, .5)',
        fill: 'start',
      };

      console.log({
        labels,
        datasets: [dataset],
      });

      return {
        labels,
        datasets: [dataset],
      };
    },
    chartOptions() {
      return {
        scales: {
          x: {
            type: 'time',
            time: {
              // Luxon format string
              tooltipFormat: 'yyyy-LL-dd HH:mm:ss',
            },
            title: {
              display: false,
            }
          },
          y: {
            display: true,
            type: 'logarithmic',
          }
        },
      };
    },
    errorsTableData() {
      if (!this.site) return;

      if (
        ('hours' === this.scope && (!this.scopeHours || this.scopeHours <= 0))
        || !this.scopeDays || this.scopeDays <= 0
      ) return;

      const end = moment();
      const start = moment(end).subtract(
        'hours' === this.scope ? this.scopeHours : this.scopeDays,
        this.scope || 'days'
      );

      const subResults = this.filterBetween(
        this.site.results.filter(result => 200 != result.status),
        start,
        end,
      );

      subResults.sort((a, b) => a.date < b.date ? -1 : 1);

      return subResults;
    },
  },
  methods: {
    fetchData() {
      this.axios
        .get(`${apiBasePath}/${this.id}`)
        .then(response => {
          if (response.status !== 200) return;

          this.site = response.data;
        })
        .catch(() => {
        })
        .then(() => {
          this.loading = false;
        });
    },
    getStatus(results) {
      if (!results) return;

      if (
        ('hours' === this.scope && (!this.scopeHours || this.scopeHours <= 0))
        || !this.scopeDays || this.scopeDays <= 0
      ) return;

      const end = moment();
      const start = moment(end).subtract(
        'hours' === this.scope ? this.scopeHours : this.scopeDays,
        this.scope || 'days'
      );

      const subResults = this.filterBetween(
        results,
        start,
        end,
      );

      /*
      const subResults = this.filterBetween(
        results,
        moment().startOf('day'),
        moment().endOf('day'),
      );
      */

      let hasSuccess = false;
      let hasError = false;

      for (const result of subResults) {
        if (result.responded && 200 == result.status)
          hasSuccess = true;
        else if (!result.responded || 200 !== result.status)
          hasError = true

        if (hasSuccess && hasError)
          break;
      }

      if (!hasError && hasSuccess)
        return 'success';
      else if (hasError && !hasSuccess)
        return 'error';
      else if (hasError && hasSuccess)
        return 'warning';
      else if (!hasError && !hasSuccess)
        return 'undefined';
    },
    filterBetween(results, start, end) {
      return results.filter(result => {
        return moment(result.date).isSameOrAfter(start)
          && moment(result.date).isSameOrBefore(end)
      });
    },
    lastUpdate(results) {
      let last = null;
      for (const result of results) {
        const mDate = moment(result.date);
        if (null === last || mDate.isAfter(last))
          last = mDate;
      }
      return last ? last.format('YYYY-MM-DD HH:mm:ss') : null;
    },
    simpleDate(date) {
      return moment(date).format('YY-MM-DD HH:mm');
    },
  },
}
</script>

<style scoped>
.app-status {
  padding-top: 20px;
}

.app-status__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #21252b;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.header__icon [color=success] {
  fill: green;
}
.header__icon [color=error] {
  fill: red;
}
.header__icon [color=warning] {
  fill: orange;
}
.header__icon [color=undefined] {
  fill: grey;
}

.header__icon > svg {
  height: 30px;
  width: 30px;
}

.header__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: 20px;
}

.infos__primary {
  font-weight: bold;
  font-size: 1.2em;
}

.infos__secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: .8em;
  color: darkgrey;
  fill: darkgrey;
  margin-top: 5px;
}

.infos__secondary > .text {
  margin-left: 5px;
}

.table-striped > tbody > tr:nth-of-type(even) .btn-dark {
  background-color: var(--bs-table-striped-bg);
  border-color: var(--bs-table-striped-bg);
}

.modal-dark .modal-content {
  background-color: #282c34;
  border-color: rgba(40, 44, 52, .2);
}

.modal-dark .modal-header {
  background-color: #1a1d24;
  border-bottom-color: #1a1d24;
}

.modal-dark .modal-header .btn-close {
  filter: invert(1);
}
</style>
